import styled from 'styled-components'
import media from 'styled-media-query'

export const Obrigado = styled.div`
    display: grid;
    place-items: center;
    height: 60vh;

    margin-top: -80px;

    h1 {
        font-family: 'Montserrat', sans-serif;
        font-size: 2rem;
        font-weight: 700;
        color: #29559C;

        text-align: center;
        padding: 20px;

        ${media.lessThan("medium")`
            font-size: 1.3rem;
        `}
    }
`