import React from 'react'

import Layout from "../components/Layout"
import SEO from "../components/seo"
import Obrigado from "../components/Obrigado"

export default () => (
  <Layout title="Obrigado!">
    <SEO title="Obrigado" />
    <Obrigado />
  </Layout>
)
