import React from 'react'

import * as S from './styled'

const Obrigado = () => {
    return (
        <S.Obrigado>
            <h1>Sua mensagem foi recebida com sucesso!</h1>
        </S.Obrigado>
    )
}

export default Obrigado